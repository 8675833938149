<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('categories') }}</h1>
                </div>
               
                <BluePlusButton to="/categories/add">{{ $translate('add-a-category') }}</BluePlusButton>
            </div>
          

            <TableAdvanced 
                v-model="sortCol" 
                :rows="filteredRows" 
                :columns="columns" 
                :sortFieldsForDropdown="sortFieldsForDropdown" 
                
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
            >
                <template v-slot:filters>
					<div class="filter-options__items flex-auto">
						<div class="filter-options__search">
							<input type="search" class="input bg-left search-icon" :placeholder="$translate('search')" v-model="rowsFilters.search" />
						</div>
					</div>
                  
                </template>

                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check">
                            <input type="checkbox" name="product" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                            <span class="check"></span>
                            <router-link :to="`/categories/${ slotData.r.id}`" class="text">
                                <inline-svg :src="require(`@/assets/img/ico-bullet-list.svg`)" />
                                <span class="ml-10">{{  slotData.r.name }}</span>
                            </router-link>
                        </label>
                    </td>
                    <td>
                        <div class="text-center">
                            {{  slotData.r.products.items ?  slotData.r.products.items.length : "-" }}
                        </div>
                    </td>
                    <td class="text-end">
                        <div class="table-actions flex items-center justify-end">
                            <button type="button" class="btn btn-transp" @click="addProductsOpen(slotData.r.id,  slotData.r.name)">
                                <SvgIcons icon="ico-remove.svg" styles="transform: rotate(45deg);" />
                                <span>{{ $translate('add-products') }}</span>
                            </button>
                            <button type="button" class="btn btn-icon red-color" @click="confirmDelete(slotData.r.id, $translate('do-you-confirm-delete-file?'))">
                                <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                            </button>
                            <!-- before translate  @click="confirmDelete(slotData.r.id,'Delete Product Category?')" -->
                        </div>
                    </td>
                </template>
            </TableAdvanced>
        </main>


		<Pagination v-model="filteredRows" :items="rawSource" />

        <AddProducts
            v-if="addProductsPopupInfo.show"
            :products="addProductsPopupInfo.products"
            @close="addProductsClose"
            @submit="addProductsSubmit"
            :processing="aProcessing"
            :title="$translate('add-product-to-category')"
        />
        <!-- before stranslate title="Add product to category " -->
    </div>
</template>

<script>
import { listCategories } from "@/graphql/queries";
import { API } from 'aws-amplify';
import {
    createProductsCategory,
    deleteCategory
} from '@/graphql/mutations';

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import { mixTables,mixDeleteItem,mixArrayFields } from "@/mixins";

export default {
    name: "Categories",
    components: {
        ...Popups,
        ...Base,
        ...Buttons
    },
    mixins: [mixTables,mixDeleteItem,mixArrayFields],
    data() {
        return {
			source: "categories",
			columns: [
                { name: "Category", filter: "name", class:"w-100" },
                { name: "Number of Products", filter: "productNumber"},
				{ name: "Actions", class:"text-end"}
            ],
            sortFieldsForDropdown: [
                { name: "Name", filter: "name" },
                { name: "Number of products", filter: "productNumber" },
                { name: "Last update date", filter: "updatedAt" },
                { name: "Date of creation", filter: "createdAt" },
            ],
			calculatedFields:[{
				filter: 'productNumber',
				path: ['products','items'],
				calcFunction: (obj)=>obj.length
			}], 
            deleteQueryType:"deleteCategory",
        };
    },
    computed: {
        userData() {
            return this.$store.state.userData || {};
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        categories() {
            return this.$store.state.categories || [];
        },
        products() {
            return this.$store.state.products || [];
        },
    },
    methods: {
        addProductsOpen(categoryID) {
            let currentCategory=this.categories.find(c=>{
                return c.id==categoryID
            })
            let currentCategoryProducts=currentCategory.products.items.length>0 ? this.transformFieldToId(currentCategory.products.items) : [];
            this.addProductsPopupInfo.products=this.filterFreeObjects(this.products,"category",currentCategoryProducts)
            this.addProductsPopupInfo.category=currentCategory
            this.addProductsPopupInfo.show=true
        },
 
        addProductsClose() {
            this.addProductsPopupInfo.products=null
            this.addProductsPopupInfo.сategory=null
            this.addProductsPopupInfo.show = false;
        },
        
        async addProductsSubmit(ev) {
            console.log("submit event",ev,this.addProductsPopupInfo.category);
            this.aProcessing=true
            let categoryProductsId=this.addProductsPopupInfo.category.id
            const requests = ev.map((productID) =>
                API.graphql({
                    query: createProductsCategory,
                    variables: {
                        input: {
                            productID: productID,
                            categoryID:  categoryProductsId  
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);
            this.refreshData();
            this.aProcessing=false
            this.addProductsClose();
        },
        async additionalDeleteActions(id){
            let category=this.categories.find(o=>{
                return o.id===id  
            })
            if(category){
                for(let relation of category.products.items){
                    await this.deleteQuery("deleteProductsCategory", relation.id)
                }
            }
        }

        
       
    },
    created(){
        this.highestToLowest.push('productNumber');
    }
};
</script>
